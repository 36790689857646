"use strict";

var _interopRequireDefault = require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/interopRequireDefault.js")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/esm/slicedToArray.js"));
function OutputDate(_ref) {
  var field = _ref.field,
    object = _ref.object;
  var value = object[field];
  if (value) {
    // Extract year, month, and day assuming format is YYYY-MM-DD
    var _value$split = value.split('-'),
      _value$split2 = (0, _slicedToArray2["default"])(_value$split, 3),
      year = _value$split2[0],
      month = _value$split2[1],
      day = _value$split2[2];
    // Create a mapping of month numbers to month names
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var formattedDate = "".concat(monthNames[parseInt(month, 10) - 1], " ").concat(parseInt(day, 10), ", ").concat(year);
    return formattedDate;
  }
  return null;
}
var _default = OutputDate;
exports["default"] = _default;