"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function classNames() {
  var set = new Set();
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  args.forEach(function (arg) {
    if (arg === undefined) return;
    if (typeof arg === 'string') {
      arg.split(' ').forEach(function (i) {
        return set.add(i);
      });
    }
  });
  return Array.from(set).join(' ');
}
var _default = classNames;
exports["default"] = _default;