import React, { useState, useEffect, useRef } from "react";
import { OffCanvas, Menu } from "nq-component";
import NavBar from "../components/Navbar";
import Switch from "../components/Switch";
import menus from "../menus.json";
import Main from "./main";
import "../scss/index.scss";
import Navbar2 from "../components/Navbar2";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Subscription from "./subscription";
import Signup from "./signup";
import Login from "./loginuser";

function Home() {
  const [collapsed, setCollapse] = React.useState(false);
  const [darkTheme, setDarkTheme] = useState(true);

  function toggleDarkMode() {
    setDarkTheme(!darkTheme);
  }

  function onClickItem(e) {
    e.preventDefault();
    let target = e.target;
    if (target.tagName.toLowerCase() !== "a") {
      // Loop through parent elements until an <a> tag is found
      while (target && target.tagName.toLowerCase() !== "a") {
        target = target.parentElement;
      }
    }
    const id = target.getAttribute("href");
    const targetElement = document.querySelector(id);
    targetElement.scrollIntoView({
      behavior: "smooth",
    });
    setCollapse(false);
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <OffCanvas onSetShow={setCollapse} show={collapsed}>
                <div className={`offcanvas-body sidebar `}>
                  {/* <div className={`offcanvas-body ${darkTheme ? "sidebar":" bg-white"} `}> */}
                  <nav className="navbar-dark">
                    <div className="d-flex p-2">
                      {/* <img className="w-100 h-auto img-fluid"
                                  style={{filter: 'invert(100%)'}} src="/logo.svg"/> */}
                      <div className="p-2 text-white w-75 text-center mb-5">
                        <h5 className="m-0 fw-bold text-center">NQ Platform</h5>
                        {/* <p className="text-truncate m-0">
                                
                                  </p> */}
                      </div>
                    </div>
                    <hr className="dropdown-divider bg-white" />
                    <Menu menus={menus} onClickItem={onClickItem.bind(this)} />
                  </nav>
                </div>
              </OffCanvas>

              <main className="vh-100 d-flex flex-column ">
                <NavBar
                  onClickNavigate={() => setCollapse(!collapsed)}
                  className={`d-md-none border-dark border-5 ${
                    darkTheme ? "darkNavbar text-white" : "bg-white"
                  }`}
                  darkTheme={darkTheme}
                  title="NQ Platform"
                  action={() => (
                    <Switch onChange={toggleDarkMode} checked={darkTheme} />
                  )}
                />

                {/* Navbar Display in Desktop/Laptop */}
                <Navbar2
                  toggleDarkMode={toggleDarkMode}
                  darkTheme={darkTheme}
                />

                <Main darkTheme={darkTheme} changes="SDDS" />
              </main>
            </>
          }
        />

        <Route path="/subscription" element={<Subscription />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/*" element={<p>Page not Found</p>} />
      </Routes>
    </Router>
  );
}

export default Home;
