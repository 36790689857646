import React from "react";
import { motion } from "framer-motion";

const index = ({ clients, darkTheme }) => {
  return (
    <section
      id="clients"
      className={`py-5 text-center container-transition ${
        darkTheme ? "custom-bg-dark2 text-white" : "custom-bg-light text-dark"
      }`}
    >
      <div className="container p-3 px-lg-5 py-lg-4">
        <h2 className="fw-bold">Valued Clients</h2>
        <p>A Pleasure to Work With</p>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-9 mx-5 px-5 align-items-center">
            <div className="row">
              {clients.map((client, index) => (
                <div key={"client-" + index} className="col-6 col-md-4 mt-3">
                  <img className="img-fluid grayscale" src={client.logo} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
