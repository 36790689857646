import React from 'react'
import { motion } from "framer-motion";
import FoundationComponent from '../../FoundationComponent'

const index = ({foundation,darkTheme,setFoundationCurrentBtn,foundationCurrentBtn}) => {
  return (
    <section
    id="more"
    className={`py-5 container-transition ${
      darkTheme
        ? "custom-bg-dark2 text-white"
        : "custom-bg-light text-dark"
    } `}
    style={{
      paddingBottom: "5rem",
    }}
  >
    <div className="container p-3 px-lg-5 py-lg-4 overflow-hidden">
      <div className="row">
        <div className="col-md-5 text-center text-md-start pe-md-5 ">
          <motion.h2
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.1, duration: 0.8 }}
            viewport={{ once: true }}
            className={`fw-bold ${
              darkTheme ? "text-white glowing" : "text-dark"
            }`}
          >
            The foundation you need to build professional apps
          </motion.h2>

          <motion.p
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.8 }}
            viewport={{ once: true }}
            className="mt-4"
          >
            It doesn't matter if this is your first build, or you're
            scaling up, we are ready to help you in any phase of
            development.
          </motion.p>
        </div>

        <motion.div
          initial={{ x: 80, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
          viewport={{ once: true }}
          className="col-md-7 mt-5 mt-md-0"
        >
          <div class="container ">
            <div class="row">
              <div class="col p-0 ">
                <button
                  class={`foundation-custom-btn w-100 h-100 ${
                    darkTheme ? "text-white" : "text-dark"
                  }`}
                  onClick={(e) => setFoundationCurrentBtn(1)}
                >
                  Scale
                </button>
              </div>
              <div class="col p-0 ">
                <button
                  class={`foundation-custom-btn w-100 h-100 ${
                    darkTheme ? "text-white" : "text-dark"
                  }`}
                  onClick={(e) => setFoundationCurrentBtn(2)}
                >
                  Security
                </button>
              </div>
              <div class="col p-0 ">
                <button
                  class={`foundation-custom-btn w-100 h-100 ${
                    darkTheme ? "text-white" : "text-dark"
                  }`}
                  onClick={(e) => setFoundationCurrentBtn(3)}
                >
                  Flexibility
                </button>
              </div>
            </div>
          </div>

          <div className="" style={{ height: "25rem" }}>
            {foundation?.map((item, index) => (
              <div
              
                key={`foundation-${index}`}
              >
                {foundationCurrentBtn === 1 && index === 0 && (
                  <FoundationComponent
                    item={item}
                    index={index}
                    currentBtn={foundationCurrentBtn}
                    darkTheme={darkTheme}
                  />
                )}

                {foundationCurrentBtn === 2 && index === 1 && (
                  <FoundationComponent
                    item={item}
                    index={index}
                    currentBtn={foundationCurrentBtn}
                    darkTheme={darkTheme}
                  />
                )}

                {foundationCurrentBtn === 3 && index === 2 && (
                  <FoundationComponent
                    item={item}
                    index={index}
                    currentBtn={foundationCurrentBtn}
                    darkTheme={darkTheme}
                  />
                )}
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  </section>
  )
}

export default index