import BasicIcon from "./assets/images/basic_icon.png";
import AdvancedIcon from "./assets/images/advanced_icon.png";
import PremiumIcon from "./assets/images/premium_icon.png";

export const subscription = [
  {
    plan: "Basic",
    priceAnnually: "854.00",
    priceMonthly: "1,281.00",
    features: [
      "Ideal for prototyping and small projects",
      "Includes essential cloud functionalities",
      "2 GB storage with AI and plugin basics",
    ],
    icon: BasicIcon,
    colors: {
      main: "#F08F1B",
      secondary: "#fef4e8",
    },
    fullFeatures: {
      requestsPerMonth: "500k",
      dataStorage: "2GB",
      fileStorage: "250GB",
      appsPerSubscription: "1",
      hasDailyBackup: true,
      hasDataRecovery: true,
      supportOptions: "Community, Ticket",
    },
  },

  {
    plan: "Advanced",
    priceAnnually: "4,558",
    priceMonthly: "6,837",
    features: [
      "Great for growing startups",
      "Enhanced features for scaling",
      "More AI options and plugin variety",
    ],
    icon: AdvancedIcon,
    colors: {
      main: "#772efc",
      secondary: "#f1eaff",
    },
    fullFeatures: {
      requestsPerMonth: "500k",
      dataStorage: "2GB",
      fileStorage: "250GB",
      appsPerSubscription: "1",
      hasDailyBackup: true,
      hasDataRecovery: true,
      supportOptions: "Community, Ticket",
    },
  },

  {
    plan: "Premium",
    priceAnnually: "8,899",
    priceMonthly: "13,498.5",
    features: [
      "For high-load enterprise use",
      "Top-tier resources and analytics",
      "Advanced AI and custom plugins",
    ],
    icon: PremiumIcon,
    colors: {
      main: "#29A0AD",
      secondary: "#eefafa",
    },
    fullFeatures: {
      requestsPerMonth: "500k",
      dataStorage: "2GB",
      fileStorage: "250GB",
      appsPerSubscription: "1",
      hasDailyBackup: true,
      hasDataRecovery: true,
      supportOptions: "Community, Ticket",
    },
  },
];

export const subscriptionPlans = [
  {
    features: "API Requests/Month ",
    basic: "500k",
    advanced: "5M",
    premium: "Unlimited",
  },
  {
    features: "Storage",
    basic: "2GB",
    advanced: "4GB",
    premium: "8GB",
  },
  {
    features: "File Hosting",
    basic: "250GB",
    advanced: "1TB",
    premium: "2TB",
  },
  {
    features: "Apps per subscription",
    basic: "1",
    advanced: "1",
    premium: "5",
  },

  {
    features: "Daily backup",
    basic: true,
    advanced: true,
    premium: true,
  },
  {
    features: "Data recovery",
    basic: false,
    advanced: true,
    premium: true,
  },
  {
    features: "Support Level  ",
    basic: "Basic",
    advanced: "Enhanced",
    premium: "Premium",
  },
  {
    features: "Support Channels",
    basic: "Community, Ticket",
    advanced: "Community, Ticket,Chat",
    premium: "Community, Ticket,Chat, Scheduled Calls",
  },
  {
    features: "File Upload Limit",
    basic: "20MB",
    advanced: "50MB",
    premium: "Custom",
  },

  {
    features: "Real-Time Data Sync",
    basic: false,
    advanced: true,
    premium: true,
  },
  {
    features: "AI & Machine Learning",
    basic: false,
    advanced: true,
    premium: true,
  },
  {
    features: "Plugin Access",
    basic: "Basic",
    advanced: "Extended",
    premium: "Full",
  },
  {
    features: "Scalability Options",
    basic: false,
    advanced: true,
    premium: true,
  },
  {
    features: "Customization Options",
    basic: false,
    advanced: true,
    premium: true,
  },
];

export const faq = [
  // Existing FAQs
  // ...

  // New FAQ entries
  {
    question: "Can I host multiple applications on a single plan?",
    answer:
      "Yes, our Premium Plan allows you to host up to five applications, ensuring you can scale your operations efficiently within a single plan.",
  },
  {
    question: "What happens if I exceed my plan's limits?",
    answer:
      "If you exceed your plan's limits, we'll notify you and suggest the most suitable upgrade. We ensure no immediate disruptions to your service, giving you time to make the right decision for your app's growth.",
  },
  {
    question: "Do you offer custom plan options?",
    answer:
      "Absolutely! If our standard plans don't meet your needs, we're happy to discuss a custom plan. Contact our sales team for a consultation.",
  },
  {
    question: "Is there a discount for annual billing?",
    answer:
      "We offer a significant discount for users who choose annual billing over monthly. This can be selected during the upgrade process or by contacting our support team.",
  },
  {
    question: "How secure is my data with NQ Platform?",
    answer:
      "Data security is our top priority. We use industry-standard encryption and best practices to ensure your data is secure and compliant with regulatory standards.",
  },
  {
    question: "Do you provide migration support?",
    answer:
      "Yes, we offer migration support for new customers looking to move their applications to our platform. Our technical team will work with you to ensure a smooth transition.",
  },
];
// export default subscription;
