import React from "react";
import { motion } from "framer-motion";

const FoundationComponent = ({ item, darkTheme }) => {
  return (
    <>
      <div className="mt-5">
        <p className="fs-5 fw-semibold ">{item.title}</p>
        <p>{item.description}</p>
        <p>{item.subDescription}</p>

        <div className="container">
          <div className="row">
            {item.icons.map((list, index) => (
              <div className="col mt-3 d-flex fw-medium align-items-center justify-content-center flex-column p-3 ">
                {darkTheme ? (
                  <img src={list.imageLight} className="" />
                ) : (
                  <img src={list.imageDark} className="" />
                )}

                {/* {list.imageDark} */}
                <p className="mt-3 text-center">{list.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FoundationComponent;
