"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function getValue(condition, value, defaultValue) {
  return condition ? value : defaultValue;
}
var _default = getValue;
exports["default"] = _default;