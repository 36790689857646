import React from 'react'
import { motion } from "framer-motion";


const index = ({solutions,darkTheme}) => {
  return (
    <section
            id="solutions"
            className={` pt-5 text-center container-transition ${
              darkTheme
                ? "custom-bg-dark text-white"
                : "custom-bg-light text-dark"
            }`}
            style={{
              paddingBottom: "5rem",
            }}
          >
            <div className="container p-3 px-lg-5 py-lg-4">
              <motion.h2
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1, duration: 0.8 }}
                viewport={{ once: true }}
                className={`fw-bold ${darkTheme && "glowing"}`}
              >
                Providing you a solution
              </motion.h2>

              <div className="row">
                {solutions.map((solution, index) => (
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.3 + index * 0.2, duration: 0.8 }}
                    viewport={{ once: true }}
                    key={"solution-" + index}
                    className="col-md-3 mt-5"
                  >
                    <img
                      className="invert"
                      height="90"
                      src={solution.icon}
                      alt={solution.title}
                    />
                    <h5 className="mt-3">{solution.title}</h5>
                    <p>{solution.description}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>
  )
}

export default index