"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function createOption(label) {
  return {
    label: label,
    value: label
  };
}
var _default = createOption;
exports["default"] = _default;