import React, { useState, useEffect, useRef } from "react";
import InputString from "nq-component/dist/InputString";
import InputPassword from "nq-component/dist/InputPassword";
// import "../scss/index.scss";

function Login({ darkTheme, changes }) {
  const handleInputChange = (e) => {
    console.log("Input Value:", e);
  };
  return (
    <div style={{ backgroundColor: "#10203a" }} className="col vh-100">
      <div className="row justify-content-center">
        <div className="col-5 d-flex mt-5 ">
          <div className="bg-white p-5 rounded-start-2">
            <InputString
              placeholder="Email"
              onChange={handleInputChange}
              className="mb-5"
            />

            <InputPassword placeholder="Password" />

            <button className="mt-5 w-100 py-2">Login</button>
          </div>

          <div className="bg-info rounded-end-2">login image</div>
        </div>
      </div>
    </div>
  );
}

export default Login;
