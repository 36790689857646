"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function hasAccess(roles, access) {
  if (access === undefined) {
    return true;
  }
  if (roles.some(function (role) {
    return access.includes(role === null || role === void 0 ? void 0 : role.name);
  })) {
    return true;
  }
  return false;
}
var _default = hasAccess;
exports["default"] = _default;