import React from 'react'
import { motion } from "framer-motion";


const index = ({darkTheme}) => {
  return (
    <section
            id="dont-start-scratch"
            className={` container-transition  ${
              darkTheme ? "custom-bg-dark home" : "custom-bg-light"
            } `}
            style={{
              paddingBottom: "1rem",
              paddingTop: "3rem",
            }}
          >
            <div className="container p-3 px-lg-5 py-lg-4 ">
              <div className="row">
                <div className="text-center text-md-start col-md-6 lh-base">
                  <motion.h2
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.1, duration: 0.8 }}
                    viewport={{ once: true }}
                    className={`fw-bold  pe-md-5 ${
                      darkTheme ? "text-white glowing" : "text-dark"
                    }`}
                  >
                    Don’t start from scratch each time you build
                  </motion.h2>

                  <motion.p
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.3, duration: 0.8 }}
                    viewport={{ once: true }}
                    className={`fw-medium mt-4 pe-md-5 ${
                      darkTheme ? "text-white" : "text-dark"
                    }`}
                  >
                    We have everything you need to transform your backend from a
                    job that takes months, to something that can be ready in
                    weeks, even days.
                  </motion.p>
                </div>

                <div
                  className={`col-md-6 text-center overflow-hidden  text-md-start mt-3 mt-md-0 ${
                    darkTheme ? "text-white" : "text-dark"
                  }`}
                >
                  <motion.div
                    initial={{ x: 80, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.6, duration: 0.8 }}
                    viewport={{ once: true }}
                    className="d-md-flex mb-2 pb-3 dont-scratch-container"
                  >
                    <div className="px-4">
                      {/* Icon */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill={`${darkTheme ? "#c1e2ff" : "#121212"}`}
                          d="M128 24c-53.83 0-96 24.6-96 56v96c0 31.4 42.17 56 96 56s96-24.6 96-56V80c0-31.4-42.17-56-96-56Zm80 104c0 9.62-7.88 19.43-21.61 26.92C170.93 163.35 150.19 168 128 168s-42.93-4.65-58.39-13.08C55.88 147.43 48 137.62 48 128v-16.64c17.06 15 46.23 24.64 80 24.64s62.94-9.68 80-24.64ZM69.61 53.08C85.07 44.65 105.81 40 128 40s42.93 4.65 58.39 13.08C200.12 60.57 208 70.38 208 80s-7.88 19.43-21.61 26.92C170.93 115.35 150.19 120 128 120s-42.93-4.65-58.39-13.08C55.88 99.43 48 89.62 48 80s7.88-19.43 21.61-26.92Zm116.78 149.84C170.93 211.35 150.19 216 128 216s-42.93-4.65-58.39-13.08C55.88 195.43 48 185.62 48 176v-16.64c17.06 15 46.23 24.64 80 24.64s62.94-9.68 80-24.64V176c0 9.62-7.88 19.43-21.61 26.92Z"
                        />
                      </svg>
                    </div>

                    <div>
                      <p className="mb-2 fw-bold fs-5">Real-Time Database</p>
                      <p className="">
                        Develop real-time applications with robust user-based
                        security by storing and managing relational data among
                        your users.
                      </p>
                    </div>
                  </motion.div>

                  <motion.div
                    initial={{ x: 80, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.8, duration: 0.8 }}
                    viewport={{ once: true }}
                    className="d-md-flex mb-2 py-3 dont-scratch-container"
                  >
                    <div className="px-4">
                      {/* Icon */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="none"
                          stroke={`${darkTheme ? "#c1e2ff" : "#121212"}`}
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M11 18.004H6.657C4.085 18 2 15.993 2 13.517c0-2.475 2.085-4.482 4.657-4.482c.393-1.762 1.794-3.2 3.675-3.773c1.88-.572 3.956-.193 5.444 1c1.488 1.19 2.162 3.007 1.77 4.769h.99a3.468 3.468 0 0 1 3.307 2.444M20 21l2-2l-2-2m-3 0l-2 2l2 2"
                        />
                      </svg>
                    </div>

                    <div>
                      <p className="mb-2 fw-bold fs-5">Cloud code functions</p>
                      <p>
                        Execute and operate server-side logic without the
                        necessity of setting up your own servers.
                      </p>
                    </div>
                  </motion.div>

                  <motion.div
                    initial={{ x: 80, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ delay: 1, duration: 0.8 }}
                    viewport={{ once: true }}
                    className="d-md-flex py-3"
                  >
                    <div className="px-4">
                      {/* Icon */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill={`${darkTheme ? "#c1e2ff" : "#121212"}`}
                          d="M12 0C5.375 0 0 5.375 0 12c0 6.627 5.375 12 12 12c6.626 0 12-5.373 12-12c0-6.625-5.373-12-12-12zm-.624 21.62v-7.528H7.19L13.203 2.38v7.528h4.029L11.376 21.62z"
                        />
                      </svg>
                    </div>

                    <div>
                      <p className="fw-bold fs-5">APIs & SDKs</p>
                      <p className="fs-6">
                        Enjoy a user-friendly and seamless experience as you
                        construct your solutions using our straightforward APIs
                        (GraphQL/REST) and SDKs.
                      </p>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default index