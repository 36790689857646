import React from 'react';

const SubscriptionTable = ({ title,price, plan, subscriptionPlans }) => {
  const renderBooleanValue = (value) => (typeof value === 'boolean' ? (value ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4edb77" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> : '❌') : value);

  return (
    <table className="d-md-none d-md-block table text-center shadow-sm border mt-4">
      <thead>
        <tr>
          <th scope="col">Full Features List</th>

          <th scope="col">
            {title}
            <p>{price}/month</p>
            <div className="text-center">
              <button type="button" className="btn text-center border">
                Choose
              </button>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        {subscriptionPlans.map((subscription, index) => (
          <tr className="" key={index}>
            <th scope="row">{subscription.features}</th>
            <td>{renderBooleanValue(subscription[plan])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SubscriptionTable;
