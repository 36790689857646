import React from "react";
import { faq } from "../../subscription";

const Faq = () => {
  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <h2 className="text-center  p-2 fw-bold ">FAQ - Frequently Asked</h2>

        <div className="col-8 mt-5">
          <div class="accordion " id="accordionExample">
            {faq.map((item, index) => {
              return (
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index + 1}`}
                      aria-expanded="true"
                      aria-controls={`collapse${index + 1}`}
                    >
                      {item.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index + 1}`}
                    class="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>{item.answer}</p>
                      {item.listOfAnswer && (
                        <ul>
                          {item.listOfAnswer.map((answer, index) => (
                            <li key={index}>{answer}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
