import React from "react";
import {NavBar as Nav} from "nq-component";

// function NavBar(props) {
//     return <Nav
//         className="shadow-sm bg-warning"
//         title={<span className="text-dark">NQ Platform</span>}
//         {...props}/>
// }

const defaultProps = {
    navigateIcon: 'bi bi-list',
}

function NavBar({className, onClickNavigate, navigateIcon, logo, title, action,darkTheme}) {
    // const classes = classNames(className, "navbar navbar-expand-lg navbar-light bg-white");
    const textColor = darkTheme ? "text-white" :"text-dark"
    return (
        <nav className={`navbar navbar-expand-lg navbar-light ${className}`}>
            <div className="container-fluid">
                <button
                    onClick={onClickNavigate}
                    type="button"
                    className={`btn btn-sm btn-link fs-4 ps-0 ${textColor}`}>
                    <i className={navigateIcon}></i>
                </button>
                <div className="d-flex align-items-center">
                    {
                        logo && (
                            <img
                                className="w-auto"
                                src={logo}
                                height="40"
                                alt="title-logo"/>
                        )
                    }
                    {
                        title && (
                            <h6 className={`fw-bold m-0 fs-5  ${textColor}`}>{title}</h6>
                        )
                    }
                </div>
                {action && action() || <div/>}
            </div>
        </nav>
    );
}

NavBar.defaultProps = defaultProps;

export default NavBar;
