"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function delayPromise(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
}
var _default = delayPromise;
exports["default"] = _default;