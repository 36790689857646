import scaleDark from './assets/foundation/scaleDark.svg'
import scaleLight from './assets/foundation/scaleLight.svg'
import featureDark from './assets/foundation/featureDark.svg'
import featureLight from './assets/foundation/featureLight.svg'

 const foundation = [

    {
      "title": "Scale with confidence",
      "description": "Keep costs in check even when you scale your apps with affordable, predictable pricing. Scaling happens automatically and, we guide the process to ensure that nothing goes wrong.",
      "subDescription": "With NQ Platform you can...",
      "icons": [{
        "imageDark": scaleDark,
        "imageLight": scaleLight,
        "text":"Scale as high as you need"
      },

      {
        "imageDark": featureDark,
        "imageLight": featureLight,
        "text":"Add new features as you grow"
      },

      {
        "imageDark": featureDark,
        "imageLight": featureLight,
        "text":"Scale vertically or horizontally"
      }]
    },
    {
        "title": "Need security?",
        "description": "Of course, you do. Keeping data safe is more important than ever.",
        "subDescription": "That's why we...",
        "icons": [{
          "imageDark": featureDark,
          "imageLight": featureLight,
          "text":"Offer GDPR compliance"
        },
  
        {
          "imageDark": featureDark,
          "imageLight": featureLight,
          "text":"Provide detailed security and checks"
        },
  
        {
          "imageDark": featureDark,
          "imageLight": featureLight,
          "text":"Make it easy to create ACLs and CLPs"
        }]
      },
      
      {
        "title": "Built-in flexibility",
        "description": "Build your app exactly the way you'd like, without getting bogged down in the backend.",
        "subDescription": "We remove the complexity from...",
        "icons": [{
          "imageDark": featureDark,
          "imageLight": featureLight,
          "text":"Multi-cloud setups"
        },
  
        {
          "imageDark": featureDark,
          "imageLight": featureLight,
          "text":"Ephemeral storage"
        },
  
        {
          "imageDark": featureDark,
          "imageLight": featureLight,
          "text":"Self hosting"
        }]
      }
  ]
  

  export default foundation