import React from 'react'
import { motion } from "framer-motion";


const index = ({technologies,darkTheme}) => {
  return (
    <section
            id="technologies"
            className={`py-5 text-center container-transition  ${
              darkTheme
                ? "custom-bg-dark text-white"
                : "custom-bg-light text-dark"
            }`}
          >
            <div className="container p-3 px-lg-5 py-lg-4 ">
              <motion.h2
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1, duration: 0.8 }}
                viewport={{ once: true }}
                className={`fw-bold ${darkTheme && "glowing"}`}
              >
                Curious about what’s under the hood?
              </motion.h2>

              <motion.p
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.8 }}
                viewport={{ once: true }}
                className="text-center col-md-8 mx-auto mt-3"
              >
                NQ Platform is a combination of open-source tools. Our backend
                platform was built using enterprise-grade, open-source products
                like:
              </motion.p>

              <div className="d-flex flex-wrap justify-content-center  align-items-center mx-auto ">
                {technologies.map((tech, index) => (
                  <motion.div
                    initial={{ y: 0, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.5 + index * 0.2, duration: 0.8 }}
                    viewport={{ once: true }}
                    key={"cycle-" + index}
                    className={`rounded shadow-sm ${
                      darkTheme
                        ? "technologies-container-dark"
                        : "technologies-container"
                    } mt-5  mx-3`}
                  >
                    <a
                      href={tech.url}
                      target="_blank"
                      className="w-100 rounded d-inline-block pb-2 pt-5"
                    >
                      <img
                        className="invert"
                        height="50"
                        src={tech.icon}
                        alt={tech.title}
                      />
                      <p
                        className={`mt-3 fw-medium  ${
                          darkTheme ? "text-white" : "text-dark"
                        }`}
                      >
                        {tech.title}
                      </p>
                    </a>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>
  )
}

export default index