"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function OutputBoolean(_ref) {
  var field = _ref.field,
    object = _ref.object;
  var value = object[field];
  if (value) {
    return "true";
  } else {
    return "false";
  }
}
var _default = OutputBoolean;
exports["default"] = _default;