"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function blobToDataUrl(blob) {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    reader.onload = function () {
      return resolve(reader.result);
    };
    reader.onerror = function (e) {
      return reject(e);
    };
    reader.readAsDataURL(blob);
  });
}
var _default = blobToDataUrl;
exports["default"] = _default;