import React, { useEffect, useState, useCallback } from "react";
import "../scss/index.scss";
import Navbar2 from "../components/Navbar2";
import { Link } from "react-router-dom";
import NQLogo from "../assets/images/logo-black.png";
import SubscriptionCard from "../components/SubscriptionCard/SubscriptionCard";
// import subscription from "../subscription";
import SubscriptionTable from "../components/SubscriptionTable/SubscriptionTable";
import Accordion from "nq-component/dist/Accordion";
import Faq from "../components/Faq/Faq";
import { subscription, subscriptionPlans } from "../subscription";
import { motion } from "framer-motion";
import PricingButton from "../components/PricingButton/PricingButton";

function Subscription({ darkTheme, changes }) {
  const [currentPlan, setCurrentPlan] = useState("Advanced");
  const [tablePrice, setTablePrice] = useState({
    basic: "854",
    advanced: "4,558",
    premium: "8,899",
  });

  const [pricingCards, SetPricingCards] = useState("annually");
  const [pricingTable, SetPricingTable] = useState("annually");

  const handleClickOnPlan = (plan) => {
    console.log("plan clicked", plan);
    setCurrentPlan(plan);
  };

  const handleButtonPrice = (e) => {
    const value = e.target.value;

    if (value === "annually") {
      setTablePrice({ basic: "854", advanced: "4,558", premium: "8,899" });
      SetPricingTable("annually");
    }

    if (value === "monthly") {
      setTablePrice({ basic: "1,400", advanced: "5,698", premium: "11,899" });
      SetPricingTable("monthly");
    }
  };

  return (
    <div className="subscription-bg">
      <motion.nav
        initial={{ y: -30, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.8 }}
        viewport={{ once: true }}
        className="d-flex justify-content-between align-items-center px-5 py-3"
      >
        <div className="text-center">
          <img
            src={NQLogo}
            style={{ width: "3rem", height: "2.5rem" }}
            alt="NQ Logo"
          />
          <p className="m-0 mt-2">NQ Platform</p>
        </div>

        <p></p>

        <ul class="nav justify-content-end">
          <li class="nav-item">
            <Link to="/#about" className="nav-link">
              About
            </Link>
          </li>
          <li class="nav-item">
            <Link to="/#contact" className="nav-link">
              Contact
            </Link>
          </li>
        </ul>
      </motion.nav>

      <div className="container  mt-3">
        <div className="row  d-flex justify-content-center ">
          <div className="col-10 text-center  ">
            <motion.h1
              initial={{ y: 25, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.8 }}
              viewport={{ once: true }}
              className="fw-semibold"
            >
              <span className="">Flexible </span>Plans
            </motion.h1>

            <motion.p
              initial={{ y: 25, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.7, duration: 0.8 }}
              viewport={{ once: true }}
              className=""
            >
              Choose a plan that works best for{" "}
              <span className=""> you and your team. </span>
            </motion.p>
          </div>
        </div>
      </div>

      <div className="container mt-3 ">
        <div className="row justify-content-around px-4 px-md-0 ">
          {/* ANNUALLY // MONTHLY */}
          <PricingButton onClick={SetPricingCards} state={pricingCards} />

          {subscription.map((subscription, index) => {
            const delay = 1 + 0.4 * index; // Calculate the delay

            return (
              <motion.div
                initial={{ y: 25, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay, duration: 0.8 }}
                viewport={{ once: true }}
                onClick={() => handleClickOnPlan(subscription.plan)}
                className={`col-md-5 col-lg-3 mt-5 mt-lg-0  subscription-card ${
                  currentPlan === subscription.plan ? "shadow" : "shadow-sm"
                }`}
                style={{
                  border:
                    currentPlan === subscription.plan
                      ? `1.5px solid ${subscription.colors.main}`
                      : "",
                }}
                key={index}
              >
                <SubscriptionCard
                  subscription={subscription}
                  currentPlan={currentPlan}
                  pricing={pricingCards}
                />
              </motion.div>
            );
          })}
        </div>
      </div>

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-10 ">
            <PricingButton
              handleTablePrice={handleButtonPrice}
              state={pricingTable}
            />

            <SubscriptionTable
              title="Basic"
              price={tablePrice.basic}
              plan="basic"
              subscriptionPlans={subscriptionPlans}
            />
            <SubscriptionTable
              title="Advanced"
              price={tablePrice.advanced}
              plan="advanced"
              subscriptionPlans={subscriptionPlans}
            />
            <SubscriptionTable
              title="Premium"
              price={tablePrice.premium}
              plan="premium"
              subscriptionPlans={subscriptionPlans}
            />

            <table className="d-none d-md-table  table text-center shadow-sm border mt-4 p-3 ">
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    Full Features List
                  </th>

                  <th scope="col ">
                    Basic
                    <p className="text-secondary">₱ {tablePrice.basic}/month</p>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn text-center border choose-btn"
                      >
                        Choose
                      </button>
                    </div>
                  </th>
                  <th scope="col ">
                    Advanced
                    <p className="text-secondary">
                      ₱ {tablePrice.advanced}/month
                    </p>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn text-center border choose-btn "
                      >
                        Choose
                      </button>
                    </div>
                  </th>
                  <th scope="col ">
                    Premium
                    <p className="text-secondary">
                      ₱ {tablePrice.premium}/month
                    </p>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn text-center border choose-btn"
                      >
                        Chooses
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-warning">
                {subscriptionPlans.map((subscription, index) => {
                  const renderBooleanValue = (value) =>
                    typeof value === "boolean" ? (
                      value ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#4edb77"
                          class="bi bi-check-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                      ) : (
                        "❌"
                      )
                    ) : (
                      value
                    );
                  return (
                    <tr className="" key={index}>
                      <th scope="row" className="text-secondary">
                        {subscription.features}
                      </th>
                      <td className="col-3">
                        {renderBooleanValue(subscription.basic)}
                      </td>
                      <td className="col-3">
                        {renderBooleanValue(subscription.advanced)}
                      </td>
                      <td className="col-3">
                        {renderBooleanValue(subscription.premium)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Faq />
    </div>
  );
}

export default Subscription;
