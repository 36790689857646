import React, { memo } from "react";
import PesoSign from "../PesoSign/PesoSign";
import CustomCheckIcon from "../CustomCheckIcon/CustomCheckIcon";
import BasicIcon from "../../assets/images/basic_icon.png";

const SubscriptionCard = ({ subscription, currentPlan, pricing }) => {
  console.log("render subscription card");
  const mainColor =
    currentPlan !== null && currentPlan === subscription.plan ? true : false;

  const price =
    pricing === "annually"
      ? subscription.priceAnnually
      : subscription.priceMonthly;

  return (
    <>
      <div className="d-flex justify-content-md-center justify-content-lg-start align-items-center">
        <div className="">
          <img src={subscription.icon} />
        </div>

        <p className="fs-2 fw-semibold m-0 ms-4 d-flex align-items-center">
          <PesoSign color={subscription.colors.main} width="30" height="30" />
          {price}
        </p>
      </div>

      <div className="mt-4 text-start">
        <div className="d-flex flex-column align-items-md-center align-items-lg-start">
          {subscription.features.map((feature, index) => {
            return (
              <div className="d-flex  " key={index}>
                <div>
                  <CustomCheckIcon
                    circleColor={subscription.colors.secondary}
                    pathColor={subscription.colors.main}
                  />
                </div>

                <p className="ms-3 fw-medium ">{feature}</p>
              </div>
            );
          })}
        </div>

        <div className="text-center mt-4">
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-md-10  d-flex  justify-content-center align-items-center">
                <PesoSign
                  color={subscription.colors.main}
                  width="24"
                  height="24"
                />

                <p
                  className="fs-4 m-0  fw-semibold"
                  style={{ color: `${subscription.colors.main}` }}
                >
                  {price}
                  <span className="fs-6 fw-normal" style={{ color: "gray" }}>
                    / month
                  </span>
                </p>
              </div>

              {pricing == "annually" && (
                <>
                  <p
                    className=" m-0 col-md-9 text-end "
                    style={{ color: "gray" }}
                  >
                    Annually: Save 15%{" "}
                  </p>
                  <p
                    className=" m-0 col-md-9 text-end text-decoration-line-through"
                    style={{ color: `${subscription.colors.main}` }}
                  >
                    $2280
                  </p>
                </>
              )}
            </div>
          </div>

          <button
            type="button"
            className="btn w-100  mt-3 py-2 fs-5 fw-semibold"
            style={{
              backgroundColor: `${
                mainColor
                  ? subscription.colors.main
                  : subscription.colors.secondary
              }`,
              color: `${mainColor ? "White" : subscription.colors.main}`,
            }}
          >
            Choose Plan
            <span className="ms-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 9"
              >
                <path
                  fill="currentColor"
                  d="M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5Z"
                />
                <path
                  fill="currentColor"
                  d="M10 8.5a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71l3.15-3.15l-3.15-3.15c-.2-.2-.2-.51 0-.71c.2-.2.51-.2.71 0l3.5 3.5c.2.2.2.51 0 .71l-3.5 3.5c-.1.1-.23.15-.35.15Z"
                />
              </svg>{" "}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default memo(SubscriptionCard);
