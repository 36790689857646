"use strict";

var _interopRequireDefault = require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/interopRequireDefault.js")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/esm/slicedToArray.js"));
function filterFields(fields, key) {
  return Object.entries(fields).reduce(function (acc, _ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
      field = _ref2[0],
      options = _ref2[1];
    if (options[key]) acc.push(field);
    return acc;
  }, []);
}
var _default = filterFields;
exports["default"] = _default;