import React, { useState, useEffect, useRef } from "react";

function Signup({ darkTheme, changes }) {
  return (
    <div>
      <p>Signup</p>
    </div>
  );
}

export default Signup;
