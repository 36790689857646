import React, { useEffect, useState,useRef } from "react";
import solutions from "../solutions.json";
import clients from "../clients.json";
import cycles from "../cycles.json";
import technologies from "../technologies.json";
import contacts from "../contacts.json";
import unique from "../unique.json";
import foundation from "../foundation.js";
import { InputString, InputNumber, InputText, Button } from "nq-component";
import { upsertUseCase } from "../usecases";
import "../scss/index.scss";
import FoundationSection from "../components/FoundationComponent";
import bgNight from "../assets/images/bg_night.svg";
import bgLight from "../assets/images/bg_light.svg";
import { motion } from "framer-motion";
import ProvideSolution from "../components/Sections/ProvideSolution";
import Unique from "../components/Sections/Unique";
import Technologies from "../components/Sections/Technologies";
import Foundation from "../components/Sections/Foundation";
import DontScratch from "../components/Sections/DontScratch"
import Home from "../components/Sections/Home"
import Clients from "../components/Sections/Clients"
import About from "../components/Sections/About"

const upsert = upsertUseCase();

function Main({ darkTheme ,changes}) {
  const [change, setChange] = React.useState({});
  const [foundationCurrentBtn, setFoundationCurrentBtn] = useState(1);
  const [progress, setProgress] = React.useState(false);
  const mainRef = useRef(null);


  useEffect(() => {
    const mainElement = mainRef.current;

    const handleScroll = () => {
      const scrollValue = mainElement.scrollTop;
      console.log("handlescroll", scrollValue);
    };

    mainElement.addEventListener("scroll", handleScroll);

    return () => {
      mainElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const [scrollY, setScrollY] = useState(0);

  // // Create a function to handle scroll events
  // function handleScroll() {
  //   // Update the scroll position in the state
  //   setScrollY(window.scrollY);
  // }

  // // Attach a scroll event listener when the component mounts
  // useEffect(() => {
  //   // Add a scroll event listener to the window
  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // // Log the scroll position to the console whenever it changes
  // useEffect(() => {
  //   console.log("Scroll Position: ", scrollY);
  // }, [scrollY]);

  
  async function onSubmit(e) {
    e.preventDefault();
    setProgress(true);
    try {
      change.source = "Website";
      await upsert.execute("leads", change);
    } catch (e) {
      console.error(e);
    }
    setProgress(false);
  }

  function onChange(field, value) {
    setChange((state) => ({
      ...state,
      [field]: value,
    }));
  }

  const style = {
    // marginTop: "200px",
  };

  return (
    <>
      {/* <hr className="m-0 " /> */}
      
      <div ref={mainRef} className="overflow-auto" id="scrollableDiv">


        <div className="d-none d-md-block arrow-animation">
          <img src="/logo-animation-dark.png" />
        </div>

    

        {/*container p-3 px-lg-5 py-lg-4 */}
        <div className="position-relative">
       
         <Home bgLight={bgLight} bgNight={bgNight} darkTheme={darkTheme} />

         <About darkTheme={darkTheme}/>
          <DontScratch darkTheme={darkTheme} />

          <Foundation
            foundation={foundation}
            darkTheme={darkTheme}
            foundationCurrentBtn={foundationCurrentBtn}
            setFoundationCurrentBtn={setFoundationCurrentBtn}
          />

          <ProvideSolution solutions={solutions} darkTheme={darkTheme} />

          <Unique unique={unique} darkTheme={darkTheme} />

          <Technologies technologies={technologies} darkTheme={darkTheme} />

          <Clients clients={clients} darkTheme={darkTheme} />
          

          <section
            id="contact"
            className={`container-transition ${
              darkTheme
                ? "custom-bg-dark text-white"
                : "custom-bg-light text-dark"
            }`}
          >
            <div className="container p-3 px-lg-5 py-lg-4">
              <div className="row">
                <div className="col-md-6 mt-5">
                  <h2 className="fw-bold">Message Us</h2>

                  <form onSubmit={onSubmit} className="mt-3">
                    <div className="row g-3">
                      <div className="col-md-12">
                        <label className="form-label fs-sm">Full Name</label>
                        <InputString
                          onChange={onChange.bind(this, "name")}
                          className="form-control"
                          placeholder="e.g. Juan Dela Cruz"
                          type="text"
                          required
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label fs-sm">
                          Email Address
                        </label>
                        <InputString
                          onChange={onChange.bind(this, "email")}
                          type="email"
                          autoComplete="nope"
                          className="form-control"
                          placeholder="e.g. username@domain.com"
                          required
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label fs-sm">Mobile No.</label>
                        <InputNumber
                          onChange={onChange.bind(this, "mobile")}
                          autoComplete="nope"
                          className="form-control"
                          placeholder="e.g. 09987654321"
                          parse={false}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label fs-sm">Your Message</label>
                        <InputText
                          onChange={onChange.bind(this, "description")}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <Button
                        progress={progress}
                        type="submit"
                        className="btn btn-primary w-50"
                      >
                        SUBMIT
                      </Button>
                    </div>
                  </form>
                </div>

                <div className="col-md-6 mt-5">
                  <h2 className="fw-bold">Contact Us</h2>

                  <div className="mt-3">
                    <span className="fs-sm">Contact Information</span>

                    <ul className="list-group list-group-flush">
                      {contacts.map((contact, index) => (
                        <li
                          key={"contact-" + index}
                          className={`list-group-item clamp bg-transparent ${
                            darkTheme ? "font-main-color" : "text-dark"
                          }`}
                        >
                          <i className={contact.icon} />
                          <span className="ms-2 fw-light">{contact.label}</span>
                          <span className="fs-sm text-nowrap">
                            {contact.value}
                          </span>
                        </li>
                      ))}
                      {/* <li className="list-group-item clamp bg-transparent">
                        <i className="bi bi-globe-americas" />
                        <span className="ms-2 fw-light">Facebook: </span>
                        <a target="_blank" href="https://facebook.com/mweebinc">
                          MWeeb Inc
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer
          className={`text-center container-transition ${
            darkTheme
              ? "custom-bg-dark text-white"
              : "custom-bg-light text-dark"
          }`}
        >
          <div className="container p-3 px-lg-5 py-lg-4">
            <p>© 2023 innque.com All Rights Reserved</p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Main;
