import React from 'react'

const PesoSign = ({color ='#F08F1B', width ='20',height='20'}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 3.5H6V1.5H11C12.3261 1.5 13.5979 2.02678 14.5355 2.96447C15.4732 3.90215 16 5.17392 16 6.5V7.5C16 8.82608 15.4732 10.0979 14.5355 11.0355C13.5979 11.9732 12.3261 12.5 11 12.5H6V10.5H11C11.7956 10.5 12.5587 10.1839 13.1213 9.62132C13.6839 9.05871 14 8.29565 14 7.5V6.5C14 5.70435 13.6839 4.94129 13.1213 4.37868C12.5587 3.81607 11.7956 3.5 11 3.5Z" fill={color}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.5C6.26522 1.5 6.51957 1.60536 6.70711 1.79289C6.89464 1.98043 7 2.23478 7 2.5V18C7 18.2652 6.89464 18.5196 6.70711 18.7071C6.51957 18.8946 6.26522 19 6 19C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18V2.5C5 2.23478 5.10536 1.98043 5.29289 1.79289C5.48043 1.60536 5.73478 1.5 6 1.5Z" fill={color}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 5.436C2 5.17079 2.10536 4.91643 2.29289 4.7289C2.48043 4.54136 2.73478 4.436 3 4.436H17C17.2652 4.436 17.5196 4.54136 17.7071 4.7289C17.8946 4.91643 18 5.17079 18 5.436C18 5.70122 17.8946 5.95558 17.7071 6.14311C17.5196 6.33065 17.2652 6.436 17 6.436H3C2.73478 6.436 2.48043 6.33065 2.29289 6.14311C2.10536 5.95558 2 5.70122 2 5.436ZM2 8.436C2 8.17079 2.10536 7.91643 2.29289 7.7289C2.48043 7.54136 2.73478 7.436 3 7.436H17C17.2652 7.436 17.5196 7.54136 17.7071 7.7289C17.8946 7.91643 18 8.17079 18 8.436C18 8.70122 17.8946 8.95557 17.7071 9.14311C17.5196 9.33065 17.2652 9.436 17 9.436H3C2.73478 9.436 2.48043 9.33065 2.29289 9.14311C2.10536 8.95557 2 8.70122 2 8.436Z" fill={color}/>
</svg>

  )
}

export default PesoSign
