import React, { useState, useEffect } from "react";
import Switch from "../Switch";
import { motion } from "framer-motion";
import NqLogoWhite from "../../assets/images/logo-white.png";
import NqLogoBlack from "../../assets/images/logo-black.png";
import menus from "../../menus.json";
import { Link } from "react-router-dom";

function Navbar2({ toggleDarkMode, darkTheme }) {
  return (
    <nav>
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.8 }}
        className="d-none d-md-flex justify-content-between align-items-center py-2   px-4 position-absolute w-100 z-3"
      >
        <div className=" d-flex flex-column  align-items-center justify-content-center">
          <img
            src={darkTheme ? NqLogoWhite : NqLogoBlack}
            className="nq-logo"
          />
          <p
            className={`fs-6 fw-semibold mb-0 ${
              darkTheme ? "text-white" : "text-dark"
            }`}
          >
            NQ Platform
          </p>
        </div>

        <ul class="nav justify-content-center  ms-5">
          {menus.map((link) => {
            return (
              <li class="nav-item ">
                <a
                  class={`nav-link navbar2-link fs-6 ${
                    !darkTheme && "text-dark"
                  }`}
                  aria-current="page"
                  href={link.route}
                >
                  {link.name}
                </a>
              </li>
            );
          })}
        </ul>

        <div className="d-flex ">
          <Link
            to="https://platform.innque.com/signin"
            className={`me-3 navbar2-link ${
              darkTheme ? "navbar2-link" : " text-dark"
            }  `}
          >
            Login
          </Link>
          <Link
            to="https://platform.innque.com/signup"
            className={`me-3 navbar2-link ${
              darkTheme ? "navbar2-link" : " text-dark"
            } `}
          >
            Sign Up
          </Link>
          <Switch onChange={toggleDarkMode} checked={darkTheme} />
        </div>
      </motion.div>
    </nav>
  );
}
export default Navbar2;
