import React from 'react'
import { motion } from "framer-motion";
import  '../../../scss/index.scss'

const index = ({bgLight,bgNight,darkTheme}) => {
  return (
    <section
    id="home"
    className={`text-center d-flex  position-relative `}
    style={{ height: `calc(110vh - 120px)` }}
  >

    {/* Background image */}
    <div className="w-100 position-relative ">
      <img
        src={bgNight}
        alt="bgNight"
        className={`landing-img position-absolute top-0 start-0 
        ${darkTheme ? "light-theme" : "dark-theme "} w-100`}
      />

      <img
        src={bgLight}
        alt="bgLight"
        className={`landing-img position-absolute top-0 start-0 
      ${darkTheme ? "dark-theme" : "light-theme "} w-100`}
      />
    </div>

    <div className="w-100 h-100 position-absolute top-0 end-0 d-flex justify-content-center ">
      <div
        className="landing-page-container bg-red "
      >
        <motion.p
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.8 }}
          className={`fw-bold m-0 text-white fs-2 text-lg `}
        >
          WRITE THE CODE TO SAY
        </motion.p>
        <motion.p
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1, duration: 0.8 }}
          className={`fw-bold m-0  ${
            darkTheme ? "font-secondary-color" : "font-main-color"
          } fs-2 text-lg fst-italic `}
        >
          HELLO WORLD
        </motion.p>{" "}
        <motion.p
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.2, duration: 0.8 }}
          className={`fw-bold m-0 text-white fs-2 text-lg `}
        >
          OR
        </motion.p>{" "}
        <motion.p
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.4, duration: 0.8 }}
          className={`fw-bold m-0 text-white fs-2 text-lg ${
            darkTheme ? "font-secondary-color" : "font-main-color"
          }`}
        >
          WRITE THE CODE TO
        </motion.p>{" "}
        <motion.p
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.6, duration: 0.8 }}
          className={`fw-bold  ${
            darkTheme ? "font-secondary-color" : "font-main-color"
          } fs-2 text-lg fst-italic `}
        >
          CHANGE THE WORLD
        </motion.p>
        <motion.a
          href="#dont-start-scratch"
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.8, duration: 0.8 }}
          className={`${
            darkTheme
              ? " learn-more-btn-dark font-secondary-color"
              : "text-white learn-more-btn1"
          }   fw-medium px-3 py-2 d-inline-block `}
        >
          Learn more
        </motion.a>
      </div>
    </div>
  </section>
  )
}

export default index