import React from "react";
import { motion } from "framer-motion";
import astronaut from "../../../assets/images/astronaut_center_night.svg";

const index = ({ darkTheme }) => {
  return (
    <section
      id="about"
      className={` container-transition  ${
        darkTheme ? "custom-bg-dark2  home" : "custom-bg-light"
      } `}
      style={{
        paddingBottom: "4rem",
        paddingTop: "9rem",
      }}
    >
      <div className="container p-3 px-lg-5 py-lg-4 ">
        <div className="row">
          <div className="  text-center text-md-start col-md-6 lh-base">
            <motion.h2
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.1, duration: 0.8 }}
              viewport={{ once: true }}
              className={`fw-bold  pe-md-5 ${
                darkTheme ? "text-white glowing" : "text-dark"
              }`}
            >
              About Us
            </motion.h2>

            <motion.p
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.8 }}
              viewport={{ once: true }}
              className={`fw-medium mt-4 pe-md-5 ${
                darkTheme ? "text-white" : "text-dark"
              }`}
            >
              Welcome to NQ Platform, the nexus where efficiency converges with
              innovation. Dive into a realm where smart technology empowers you
              to elevate your business, outshine competitors, and experience
              unparalleled accessibility, no matter where you are. Remember,
              with the prowess of NQ at your fingertips, there are truly no
              limits to what you can envision and create. Embrace the future
              with us.!
            </motion.p>
          </div>

          <div
            className={`col-md-6 text-center overflow-hidden     text-md-start mt-3 mt-md-0 ${
              darkTheme ? "text-white" : "text-dark"
            }`}
          >
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.8 }}
              viewport={{ once: true }}
            >
            
              <img src={astronaut} className="mt-5" />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
