"use strict";

var _interopRequireDefault = require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/interopRequireDefault.js")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));
var _objectToOption = _interopRequireDefault(require("./objectToOption"));
function GetOption(collection, word, indexes, find, where, callback, keys, sort, includes) {
  var query = {
    limit: 100,
    where: (0, _objectSpread2["default"])({}, where),
    keys: [].concat((0, _toConsumableArray2["default"])(keys), (0, _toConsumableArray2["default"])(indexes)),
    includes: includes
  }; // don't mutate where
  if (sort) query.sort = sort;
  if (word && indexes.length > 0) {
    query.where['$or'] = indexes.map(function (index) {
      var or = {};
      or[index] = {
        '$regex': word,
        '$options': 'i'
      };
      return or;
    });
  }
  clearTimeout(this.timeout);
  this.timeout = setTimeout(function () {
    find.execute(collection, query).then(function (objects) {
      var options = objects.map(function (obj) {
        return (0, _objectToOption["default"])(obj, indexes);
      });
      if (!sort) {
        // Only perform manual sort if sort parameter is not defined
        options = options.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
      }
      callback(options);
    });
  }, 500);
}
var _default = GetOption;
exports["default"] = _default;