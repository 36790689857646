"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function drawImage(c, image, cropper) {
  var x = cropper.photo.x;
  var y = cropper.photo.y;
  var width = cropper.photo.getWidth();
  var height = cropper.photo.getHeight();
  c.drawImage(image, x, y, width, height);
}
var _default = drawImage;
exports["default"] = _default;