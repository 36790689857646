import React from 'react'
import { motion } from "framer-motion";


const index = ({unique,darkTheme}) => {
  return (
    <section
            id="unique" 
            className={`py-5 text-center container-transition ${
              darkTheme
                ? "custom-bg-dark2 text-white"
                : "custom-bg-light text-dark"
            }`}
          >
            <div className="container p-3 px-lg-5 py-lg-4">
              <motion.h2
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1, duration: 0.8 }}
                viewport={{ once: true }}
                className={`fw-bold ${darkTheme && "glowing"} `}
              >
                What makes us
                <span className={`${darkTheme && "ml-1 font-secondary-color"}`}>
                  {" "}
                  unique?
                </span>
              </motion.h2>
              <div className="d-flex flex-wrap justify-content-center mt-5 align-items-center ">
                {unique.map((unique, index) => (
                  <motion.div
                    initial={{ y: 25, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.3 + index * 0.2, duration: 0.8 }}
                    viewport={{ once: true }}
                    key={"unique-" + index}
                    className={`${
                      darkTheme
                        ? "unique-title-container-dark"
                        : " unique-title-container"
                    } shadow-sm m-2 py-2 px-3 rounded`}
                  >
                    <p
                      className={`mb-0 fw-semibold  ${
                        darkTheme && "font-secondary-color"
                      }`}
                    >
                      {unique.title}

                      <span className="d-inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 256 256"
                        >
                          <path
                            fill="currentColor"
                            d="M144 180a16 16 0 1 1-16-16a16 16 0 0 1 16 16Zm92-52A108 108 0 1 1 128 20a108.12 108.12 0 0 1 108 108Zm-24 0a84 84 0 1 0-84 84a84.09 84.09 0 0 0 84-84Zm-84-64c-24.26 0-44 17.94-44 40v4a12 12 0 0 0 24 0v-4c0-8.82 9-16 20-16s20 7.18 20 16s-9 16-20 16a12 12 0 0 0-12 12v8a12 12 0 0 0 23.73 2.56C158.31 137.88 172 122.37 172 104c0-22.06-19.74-40-44-40Z"
                          />
                        </svg>
                      </span>
                    </p>
                  </motion.div>
                ))}
              </div>

              <motion.a
                initial={{ y: 0, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 1.5, duration: 0.8 }}
                viewport={{ once: true }}
                className={`d-inline-block mt-3 ${
                  darkTheme
                    ? " learn-more-btn-dark font-secondary-color"
                    : "learn-more-btn"
                } px-3 py-2 fw-semibold`}
              >
                Learn more
              </motion.a>
            </div>
          </section>
  )
}

export default index