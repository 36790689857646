import React from "react";

const PricingButton = ({ onClick, state, handleTablePrice }) => {
    
  /// Determine which function to use, `handleTablePrice` or `onClick`
  const handleAnnualClick = handleTablePrice || (() => onClick("annually"));
  const handleMonthlyClick = handleTablePrice || (() => onClick("monthly"));
  return (
    <div className="d-flex justify-content-center mb-3">
      <div className="toggle-container ">
        <div
          className={`background ${
            state === "monthly" ? "slide-right" : "slide-left"
          }`}
        ></div>

        <button
          onClick={handleAnnualClick}
          className={`${state === "annually" ? "selected" : ""} fw-semibold`}
          value="annually"
        >
          Annually
        </button>

        <button
        onClick={handleMonthlyClick}

          className={`${state === "monthly" ? "selected" : ""} fw-semibold`}
          value="monthly"
        >
          Monthly
        </button>
      </div>
    </div>
  );
};

export default PricingButton;
